<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('orderGiftCardFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton
                  size="sm" color="danger" square
                  @click="openModalEvent('create', 'giftCardCrudForm', {count:2}, 'Toplu Gift Kart Ekle')"
              > Toplu Gift Kart Ekle
              </CButton>
              <CButton
                   size="sm" color="success" square
                  @click="openModalEvent('create', 'giftCardCrudForm', {}, 'Gift Kart Ekle')"
              > Gift Kart Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="orderGiftCards"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update', 'giftCardStatusForm', selectedOrder, 'Gift Kart Kodunu Yeniden Gönder')"
            v-on:refresh="filterGiftCardOrders(filterParams)"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                    toggler-text="İşlemler"
                    class="m-2"
                    color="info"
                    size="sm"
                >
                  <CDropdownItem  size="sm" :disabled="item.giftCardStatus && item.giftCardStatus.type !== 'delivered'"  color="success"
                                  @click="openModalEvent('update', 'giftCardStatusForm', item, 'Gift Kart Kodunu Yeniden Gönder')" >
                    Yeniden Gönder
                  </CDropdownItem>
                  <CDropdownItem  size="sm" color="success" @click="openModalEvent('update', 'giftCardLimitForm', item, 'Gift Kart Limitini Güncelle')" >
                    Limit Güncelle
                  </CDropdownItem>
                  <CDropdownItem  size="sm" color="success" @click="openModalEvent('update', 'giftCardCrudForm', item, 'Gift Kart Güncelle')" >
                    Gift Kart Düzenle
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ dateFormat(item.createTime) }} </td>
            </template>
            <template #deliveryDatePlanned="{item}">
              <td> {{ item.deliveryDatePlanned && dateFormat(item.deliveryDatePlanned) }} </td>
            </template>
            <template #giftCardStatus="{item}">
              <td> <CBadge :color="colors[item.giftCardStatus.displayOrder]">  {{ item.giftCardStatus.friendlyType }}</CBadge> </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <FormModal
        :show="openModal"
        :title="modalTitle"
        :openModalEvent="openModalEvent"
        :operationEvent="crudGiftCard"
        :actionType="actionType"
        :module="module"
        :form="form"
        :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterGiftCardOrders"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import moment from "moment";
import FormModal from '../components/formModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"


export default {
  name: 'GiftCardOrders',
  components:{ FormModal, FilterSidebar},
  data() {
    return {
      colors: ['primary', 'success', 'warning', 'danger', 'info', 'dark', 'light'],
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'giftCardLimitForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'orderAlertLogFilter',
      filterParams: {},
      activePage: 1,
      loading: false,
      pages: 5,
      itemsPerPage: 10,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'Id',_style: 'font-size:12px'},
        {key: 'createTime', label: 'Oluşturulma_Tarihi',_style: 'font-size:12px'},
        {key: 'codeMasked', label: 'Gift_Cod',_style: 'font-size:12px'},
        {key: 'giftCardStatus', label: 'Sipariş_Durumu',_style: 'font-size:12px'},
        {key: 'senderName', label: 'Gönderen_Adı',_style: 'font-size:12px'},
        {key: 'senderEmail', label: 'Gönderen_Eposta',_style: 'font-size:12px'},
        {key: 'recipientName', label: 'Alıcı_Adı',_style: 'font-size:12px'},
        {key: 'recipientEmail', label: 'Alıcı_Eposta',_style: 'font-size:12px'},
        {key: 'senderNote', label: 'Not',_style: 'font-size:12px'},
        {key: 'totalAmount', label: 'Hediye_Kartı',_style: 'font-size:12px'},
        {key: 'spentAmount', label: 'Harcanan_Tutar',_style: 'font-size:12px'},
        {key: 'useCount', label: 'Kull.Adedi',_style: 'font-size:12px'},
        {key: 'remainingAmount', label: 'Kalan_Tutar',_style: 'font-size:12px'},
        {key: 'deliveryDatePlanned', label: 'Teslim_Tarihi',_style: 'font-size:12px'}
      ],
      giftCardOrdersData: {
        giftCardOrders: [],
        count: 0
      },
      filters: [{
        type: 'string',
        dataIndex: 'id',
        field: 'id',
        comparison: 'eq'
      }, {
        type: 'string',
        dataIndex: 'codeMasked',
        field: 'codeMasked'
      }, {
        type: 'string',
        dataIndex: 'giftCardStatus',
        field: 'giftCardStatus'
      }, {
        type: 'string',
        dataIndex: 'senderEmail',
        field: 'senderEmail'
      }, {
        type: 'string',
        dataIndex: 'recipientEmail',
        field: 'recipientEmail'
      },{
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt'
      },{
        type: 'date',
        dataIndex: 'endTime',
        field: 'createTime',
        comparison: 'lt'
      }]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    orderGiftCards () {
      return this.$store.getters.orderGiftCards
    }
  },
  methods: {
    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    openModalEvent(actionType, form, data, title, desc){
      if(form == 'giftCardCrudForm'){

        if(actionType == 'update'){
          data.validDateEnd = moment(data.validDateEnd).format('YYYY-MM-DD')
          data.validDateStart = moment(data.validDateStart).format('YYYY-MM-DD')
          data.deliveryDatePlanned = moment(data.deliveryDatePlanned).format('YYYY-MM-DD')
          data.giftCardStatus = data.giftCardStatus.type

        } else if(actionType == 'create') {
          data.codePrefix = "GC"
          data.giftCardStatus = "delivered"
        }
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    async crudGiftCard(item, action){
      let params;
      if(this.form == 'giftCardLimitForm'){
        params = {
          giftCardId: item.id,
          totalAmount: item.totalAmount,
          spentAmount: item.spentAmount
        }
        await this.$store.dispatch('order_updateGiftCardLimit', params)
      } else if (this.form == "giftCardStatusForm") {
        params = {
          id: item.id,
          recipientEmailAddress: item.recipientEmail
        }
        await this.$store.dispatch('order_updateGiftCardStatus', params)
      } else if (this.form == "giftCardCrudForm") {

        let formData = new FormData();

        formData.append("giftCardId", item.id ? item.id : 0)
        formData.append("orderId", item.orderId ? item.orderId  : 0)
        formData.append("actionType", action)
        formData.append("codePrefix", item.codePrefix ? item.codePrefix : '')
        formData.append("codePrefix", item.codePrefix ? item.codePrefix : '')
        formData.append("count", item.count ? item.count : 1)
        formData.append("giftCardStatus", item.giftCardStatus)
        formData.append("recipientEmail", item.recipientEmail)
        formData.append("deliveryDatePlanned", item.deliveryDatePlanned ? item.deliveryDatePlanned : '')
        formData.append("recipientName", item.recipientName)
        formData.append("senderEmail", item.senderEmail)
        formData.append("senderName", item.senderName)
        formData.append("totalAmount", item.totalAmount)
        formData.append("spentAmount", item.spentAmount ? item.spentAmount : 0)

        if (isNaN(Date.parse(item.validDateStart)))
          item.validDateStart = ''

        if (isNaN(Date.parse(item.validDateEnd)))
          item.validDateEnd = ''

        formData.append("validDateStart", item.validDateStart ? item.validDateStart : '')
        formData.append("validDateEnd", item.validDateEnd ? item.validDateEnd : '')

        await this.$store.dispatch('order_giftCardCrud', formData)
      }
      if(this.$store.getters.orderStatus.success){
        this.openModalEvent()
        this.filterGiftCardOrders(this.filterParams);
      }
    },
    rowClicked(item) {
      this.lastItem = item;
    },
    resendCode() {
      if ( this.selectedOrder.giftCardStatus === 'delivered') {
        this.updateCardModal = true;
      }
    },

    async filterGiftCardOrders(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('order_giftCards', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.filterGiftCardOrders(this.filterParams);
  }
}
</script>
